import { GlobalAttributesInterface } from "../interfaces"


export const INIT_GLOBAL_STATE: GlobalAttributesInterface = {
  useDesktop: false,
  flujo: '',
  idFlujo: 0,
  region: '',
  oficinaId: '',
  claveAlmacen: '',
  claveOficinaAlmacen: '',
  canal: '',
  idEmpleado: '',
  fuerzaVenta: '',
  agente: '',
  claveSap: ''
}
