import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { manejarError, superUrl } from '@core/helpers';
import { environment } from '@envs/environment';
import { selectAgente, selectExternalID, selectFuerzaVenta, selectPhone, selectRegion } from '@store/index';
import { catchError, map } from 'rxjs';
import { CashPaymentResponse } from '@pago/interfaces';
import { micro_origin } from '@envs/environment';

@Injectable({
  providedIn: 'root'
})
export class PagoEfectivoService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  readonly #externalID = this.#store.selectSignal(selectExternalID);
  readonly #phone = this.#store.selectSignal(selectPhone);
  readonly #region = this.#store.selectSignal(selectRegion);
  readonly #fuerzaVenta = this.#store.selectSignal(selectFuerzaVenta);
  readonly #agente = this.#store.selectSignal(selectAgente);

  #headers = new HttpHeaders({
    'x-api-key': environment.apiKey_pagoEfectivo,
    origen: micro_origin
  });

  get url() {
    return superUrl;
  }


  cashPayment() {
    return this.#http.post<CashPaymentResponse>(
      `${this.url}/api/v1/payments/efectivo/registrar`,
      this.#generatePagoEfectivoBody(),
      { headers: this.#headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject;
      }),
      catchError((error: HttpErrorResponse) => manejarError(error))
    )
  }


  #generatePagoEfectivoBody() {
    return {
      payload: {
        cuenta: this.#externalID(),
        monto: this.#phone()!.pagoInicial?.toString(),
        region: Number(this.#region()),
        fuerzaVentas: Number(this.#fuerzaVenta()),
        usuario: this.#agente(),
        estadoPago: 1
      }
    }
  }
}
