export enum ServicesNames {
  LOGIN = 'login',
  CONSULTAR_USUARIO = 'consultaUsuarios',
  REFRESH_TOKEN = 'refreshToken',

  VALIDAR_DATOS_URL = 'validarDatosUrl',
  REGISTRAR_CLIENTE = 'registrarCliente',
  VALIDAR_OTP_SMS = 'validarOTPSms',
  RETOMAR_TRAMITE_SMS = 'retomarTramiteSms',
  GET_PREGUARDADO = 'getPreGuardado',

  GET_ACCESS_TOKEN_VERIDAS = 'getAccessTokenVeridas',
  GET_CP = 'getCP',
  GET_CATALOGOS = 'getCatalogos',
  GET_INFO_CLIENTE = 'getInfoCliente',
  SED_VALIDA_OCR = 'sendValidaOCR',
  SEND_DATOS_CLIENTE = 'sendDatosCliente',
  SCORE_TELCO = 'scoreTelco',
  RENAPO = 'renapo',
  REVISION_INE = 'revisionIne',
  REGISTRAR_EMAIL = 'registrarEmail',
  VALIDAR_OTP_EMAIL = 'validarOtpEmail',
  SEND_OTP_VERIFICACION = 'sendOTPVerificacion',
  ENVIA_CORREO = 'enviaCorreo',

  TRAMITE_FUTURO = 'tramiteFuturo',

  SOLICITA_CUENTA = 'solicitaCuenta',
  GET_SOLICITUD = 'getSolicitud',
  PUT_SOLICITUD = 'putSolicitud',
  POST_PRESTAMO = 'postPrestamo',
  EMAIL_EDGE = 'emailEdge',

  SESSION_TAG_GET = 'sessionTagsGet',

  ACTUALIZAR_PAGO = 'actualizaPaso',

  EVALUADOR = 'evaluador',
  SEVERITY_LEVEL = 'severityLevel',

  MOTOR_CALCULO = 'motorCalculo',
  API_DESCUENTOS = 'apiDescuentos',

  IMEI_CHECK = 'imeiCheck',

  GET_ENVIO_EQUIPOS = 'getEnvioEquipos',

  CONSULTA_CITA_ENVIO_DISPONIBLE = 'consultaCitasEnvioDisponible',
  FOLIO_RESERVA_ENVIO = 'folioReservaEnvio',
  CANCELAR_RESERVA_ENVIO = 'cancelarReservaEnvio',
  GENERAR_CITA_ENTREGA_ENVIO = 'generarCitaEntregaEnvio',
  CANCELAR_CITA_ENTREGA_ENVIO = 'cancelarCitaEntregaEnvio',
  DETALLE_CITA_ENVIO = 'detalleCitaEnvio',

  ACTUALIZA_PASO = 'actualizaPaso',
  REGISTRAR_ENVIO_DOMICILIO = 'registrarEnvioDomicilio',
  GET_ENVIO_DOMICILIO = 'getEnvioDomicilio',
  CAC_SMS = 'cacsSmt',

  GENERAR_ORDEN_CONEKTA_SERVICE = 'generarOrdenConktaService',
  GET_ORDEN_PAGO_CONEKTA = 'getOrdenPagoConekta',

  CONFIRMACION_PAGO = 'confirmacionPago',
}
