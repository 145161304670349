import { createAction, props } from "@ngrx/store";

import { PagosPlazos } from "@equipos/interfaces";
import { PutSolicitudAttributesInterface } from "../../interfaces";


export const savePayment = createAction(
  '[Payment]: Payment Successfully Saved',
  props<{value: PagosPlazos}>()
);

export const savePutSolicitud = createAction(
  '[Payment]: Put Solicitud Attributes Successfully Saved',
  props<{value: PutSolicitudAttributesInterface}>()
);

export const savePaymentType = createAction(
  '[Payment]: Payment Type Successfully Saved',
  props<{value: 'tarjeta' | 'efectivo' | 'sicatel' | 'Enganche Cero' | 'oxxo'}>()
);

export const saveSessionTagGetProperties = createAction(
  '[Payment]: sessionId, orgId and sessionKey Successfully Saved',
  props<{sessionId: string; orgId: string; sessionKey: string;}>()
);

export const saveLastCardNumbers = createAction(
  '[Payment]: Last Card Numbers Successfully Saved',
  props<{value: string}>()
);

export const saveCardType = createAction(
  '[Payment]: Card Type Successfully Saved',
  props<{value: 'visa' | 'mastercard' | 'tarjeta'}>()
);
