import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StatusClient } from '@pago/interfaces';

import { GlobalBodyService } from '@pago/services';
import { selectIdFlujo, selectPromotionCode } from '@store/index';

@Injectable({
  providedIn: 'root'
})
export class GenerateGlobalBodyService {
  readonly #store = inject(Store);
  readonly #globalBodyService = inject(GlobalBodyService);

  readonly #idFlujo = this.#store.selectSignal(selectIdFlujo);
  readonly #promotionCode = this.#store.selectSignal(selectPromotionCode);

  get isFlowSMT() {
    return this.#idFlujo() !== 3;
  }

  get promotionCode() {
    return this.#promotionCode();
  }


  generateGlobalBodyForCatFlow(estatus: StatusClient = 'Incompleto'): any {
    const array = [
      this.#globalBodyService.generateCotizacionBody(),
      this.#globalBodyService.generateGenerarNotaBody(),
      this.#globalBodyService.generatePutSolicitud(),
      this.#globalBodyService.generatePostPrestamoBody(),
      this.#globalBodyService.generateObtenerContratoBody(),
      this.#globalBodyService.generateGenerarContratoBody(),
      this.#globalBodyService.generateFirmaContratoBody(),
      this.#globalBodyService.generateSaveContract(),
      this.#globalBodyService.generateDetalleCitaEnvioBody(),
      this.#globalBodyService.generateEnviaCorreoBody(this.isFlowSMT),

      this.#globalBodyService.generateEnviarCredecialesBody(),

      this.#globalBodyService.generateActualizarStatusClienteBody(estatus),
    ];

    if (this.promotionCode?.code) {
      return [...array, this.#globalBodyService.notificarDescuento()];
    }

    return array;
  }

  generateGlobalBodyForCatFlowAndConektaPayment(estatus: StatusClient = 'Incompleto'): any {
    return [
      this.#globalBodyService.generateCotizacionBody(),
      this.#globalBodyService.generatePutSolicitud(),
      this.#globalBodyService.generatePostPrestamoBody(),
      this.#globalBodyService.generateObtenerContratoBody(),
      this.#globalBodyService.generateGenerarContratoBody(),
      this.#globalBodyService.generateFirmaContratoBody(),
      this.#globalBodyService.generateSaveContract(),
      this.#globalBodyService.generateDetalleCitaEnvioBody(),
      this.#globalBodyService.generateEnviaCorreoBody(this.isFlowSMT),

      this.#globalBodyService.generateEnviarCredecialesBody(),

      this.#globalBodyService.generateActualizarStatusClienteBody(estatus),
    ];
  }


  generateGlobalBodyWithoutSMTServices(estatus: StatusClient = 'Incompleto'): any {
    const array = [
      this.#globalBodyService.generateCotizacionBody(),
      this.#globalBodyService.generatePutSolicitud(),
      this.#globalBodyService.generatePostPrestamoBody(),
      this.#globalBodyService.generateObtenerContratoBody(),
      this.#globalBodyService.generateGenerarContratoBody(),
      this.#globalBodyService.generateFirmaContratoBody(),
      this.#globalBodyService.generateSaveContract(),
      this.#globalBodyService.generateEnviaCorreoBody(this.isFlowSMT),

      this.#globalBodyService.generateEnviarCredecialesBody(),

      this.#globalBodyService.generateActualizarStatusClienteBody(estatus),
    ];

    if (this.promotionCode?.code) {
      return [...array, this.#globalBodyService.notificarDescuento()];
    }

    return array;
  }


  generateGlobalBodyForCacFlow(statusClient: StatusClient = 'Incompleto'): any {
    const array = [
      this.#globalBodyService.generateCotizacionBody(),
      this.#globalBodyService.generateGenerarNotaBody(),
      this.#globalBodyService.generatePutSolicitud(),
      this.#globalBodyService.generatePostPrestamoBody(),
      this.#globalBodyService.generateObtenerContratoBody(),
      this.#globalBodyService.generateGenerarContratoBody(),
      this.#globalBodyService.generateFirmaContratoBody(),
      this.#globalBodyService.generateSaveContract(),
      this.#globalBodyService.generateEnviaCorreoBody(this.isFlowSMT),

      this.#globalBodyService.generateEnviarCredecialesBody(),

      this.#globalBodyService.generateActualizarStatusClienteBody(statusClient),
    ];

    if (this.promotionCode?.code) {
      return [...array, this.#globalBodyService.notificarDescuento()];
    }

    return array;
  }
}
