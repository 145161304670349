import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { manejarError, superUrl } from '@core/helpers';
import { environment } from '@envs/environment';
import { TramiteFuturoOperation } from '../enums';
import { GetOrRemoveTramiteFuturo } from '../interfaces';
import { ServicesNames } from '@core/enums';
import { Store } from '@ngrx/store';
import { selectCreditLimit, selectNivelRiesgo, selectPhone } from '@store/index';
import { Phone } from '@equipos/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DatService {
  readonly #http = inject(HttpClient);
  readonly #store = inject(Store);

  readonly #phone = this.#store.selectSignal(selectPhone);
  readonly #severityLevel = this.#store.selectSignal(selectNivelRiesgo);
  readonly #creditLimit = this.#store.selectSignal(selectCreditLimit);

  get url() {
    return superUrl;
  }

  get phone(): Phone {
    return this.#phone()!;
  }


  registerPhoneInTramiteFuturo() {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_tramiteFuturo
    });

    console.log({limiteCredito: this.#creditLimit(), phone: this.phone});

    const body = {
      payload: {
        nivelRiesgo: this.#severityLevel(),
        jsonOferta: JSON.stringify(this.phone),
        estatusTF: "PROCESO",
        operacion: TramiteFuturoOperation.POST
      }
    }

    return this.#http.post<GetOrRemoveTramiteFuturo>(`${this.url}/${ServicesNames.TRAMITE_FUTURO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;
        return response.responseObject;
      }),
      catchError((error) => manejarError(error))
    )
  }

   getOrRemoveTramiteFuturo(operation: TramiteFuturoOperation) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_tramiteFuturo
    });

    const body = {
      payload: {
        nivelRiesgo: null,
        jsonOferta: null,
        estatusTF: null,
        operacion: operation
      }
    }

    return this.#http.post<GetOrRemoveTramiteFuturo>(`${this.url}/${ServicesNames.TRAMITE_FUTURO}`,
      body,
      { headers }
    ).pipe(
      map((response) => {
        if (!response.process) throw response.message;

        const { responseObject } = response;

        return {
          nivelRiesgo: responseObject.nivelRiesgo,
          phone: responseObject.oferta,
          status: responseObject.estatusTF
        }
      }),
      catchError((error) => manejarError(error))
    )
  }
}
