import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, map } from 'rxjs';

import { amazon, environment } from '@envs/environment';
import { RefreshTokenResponse } from '../interfaces';
import { superUrl } from '@core/helpers';
import { ServicesNames } from '@core/enums';
import { Store } from '@ngrx/store';
import { selectPhone, selectToken, selectTokenSeller } from '@store/index';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  readonly #http = inject(HttpClient);
  readonly #jwtHelperService = inject(JwtHelperService);
  readonly #store = inject(Store);

  readonly #clientToken = this.#store.selectSignal(selectToken);
  readonly #sellerToken = this.#store.selectSignal(selectTokenSeller);
  readonly #phone = this.#store.selectSignal(selectPhone);


  get url() {
    return superUrl;
  }

  get clientToken() {
    return this.#clientToken();
  }

  get sellerToken() {
    return this.#sellerToken();
  }

  get phone() {
    return this.#phone()!;
  }

  refreshToken(): Observable<string> {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey_refreshToken,
    });
    return this.#http.post<RefreshTokenResponse>(
      `${this.url}/${ServicesNames.REFRESH_TOKEN}`,
      {},
      { headers }
    ).pipe(
      map((response) => {
        return response.responseObject.refreshToken;
      })
    )
  }

  isValidToken(): boolean {
    if (this.clientToken == '' || this.clientToken == null || this.clientToken == 'tokenTelcel is null') {
      return false;
    }

    const isTokenExpired = this.#jwtHelperService.isTokenExpired(this.clientToken);
    if (isTokenExpired == true) {
      return false;
    }
    return true;
  }

  showEnrolamientoModule() {
    return Number(this.phone.gamma) >= 0 && this.isValidTokenSeller();
  }

  isValidTokenSeller(): boolean {
    return this.sellerToken !== '';
    // if (this.sellerToken == '' || !this.sellerToken) return false;
    // return this.#jwtHelperService.isTokenExpired(this.sellerToken);
  }
}
